<script>

export default {
  name: 'custom-footer-column',
  props: {
    subject: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    caption() {
      return this.subject?.subject;
    },
    itemList() {
      return this.subject?.hrefList ?? [];
    },
    getItemListWithLimit() {
      return this.subject?.hrefList?.slice(0, this.firstShowingCount + this.row);
    },
    isShowMore() {
      return !!this.subject?.hrefList
        && this.subject?.hrefList?.length > 0
        && (this.row + this.firstShowingCount < this.subject?.hrefList?.length - 1);
    },
  },
  mounted() {
  },
  data() {
    return {
      firstShowingCount: 8,
      row: 0,
      originPath: 'https://www.flyingcarpet.co.il',
      // isShowMore: false,
    };
  },
  methods: {
    showMore() {
      this.row += 6;
      // this.isShowMore = (this.row + this.firstShowingCount > this.subject?.hrefList?.length - 1);
    },
  },
};
</script>
